.App {
  text-align: center;
}
@font-face {
    font-family: 'Annie';
    src: local('AnnieUseYourTelescope'), url(./Assets/Fonts/AnnieUseYourTelescope.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Neucha';
    src: local('Neucha'), url(./Assets/Fonts/Neucha.ttf) format('truetype');
    font-display: swap;
}
@font-face {
    font-family: 'Sora';
    src: local('Sora'), url(./Assets/Fonts/Sora.ttf) format('truetype');
    font-display: swap;
}